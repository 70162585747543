// intervalObj = {
//     name (string)
//     callback (function),
//     delay (time in milliseconds)
// }

const CronJob = {
  jobsActive: new Map(),
  init: function (jobs) {
    return jobs.every((job) => {
      job.callback();
      const intervalId = setInterval(() => job.callback(), job.delay);
      this.jobsActive.set(job.name, intervalId);
      return true;
    });
  },
  add: function (job) {
    job.callback();
    const intervalId = setInterval(() => job.callback(), job.delay);
    this.jobsActive.set(job.name, intervalId);
  },
  clearAll: function () {
    Object.values(this.jobsActive).every((intervalId) => {
      clearInterval(intervalId);
    });
    this.jobsActive = new Map();
  },
  clear: function (name) {
    const intervalId = this.jobsActive.get(name);
    clearInterval(intervalId);
    this.jobsActive.delete(name);
  },
  log: function () {
    console.log("Active CronJobs", this.jobsActive);
  },
};

export default CronJob;
