import useUserStore from "@/store/user";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/views/Login-View.vue"),
  },
  {
    path: "/redirect/:url",
    name: "Redirect",
    component: () => import("@/views/Redirect-View.vue"),
  },
  {
    path: "/loggedin/postlogin",
    name: "PostLogin",
    component: () => import("@/views/Post-Login.vue"),
  },
  {
    path: "/loggedin",
    component: () => import("@/layouts/default/LoggedIn-Layout.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard-View.vue"),
      },
      {
        path: "sites",
        name: "Sites",
        children: [
          {
            path: "",
            name: "sitelist",
            component: () => import("@/views/Sites-View.vue"),
          },
          {
            path: "site/:id/event/:eventid",
            name: "siteeventitem",
            component: () => import("@/views/Event-Detail-View.vue"),
          },
          {
            path: "site/:id",
            name: "siteitem",
            component: () => import("@/views/Site-Detail-View.vue"),
          },
          {
            path: "site/:id",
            name: "siteitem",
            component: () => import("@/views/Site-Detail-View.vue"),
          },
          {
            path: "site/:id/sitealarmgroup",
            name: "sitealarmgroup",
            component: () => import("@/views/Alarm-Group-View.vue"),
          }
        ],
      },
      {
        path: "events",
        name: "events",
        children: [
          {
            path: "",
            name: "eventslist",
            component: () => import("@/views/Events-View.vue"),
          },
          {
            path: "event/:eventid",
            name: "eventslistitem",
            component: () => import("@/views/Event-Detail-View.vue"),
          },
        ],
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("@/views/Contact-View.vue"),
      },
      {
        path: "settings",
        name: "settings",
        children: [
          {
            path: "",
            name: "setting",
            component: () => import("@/views/Settings-View.vue"),
          },
          {
            path: "help",
            name: "help",
            children: [
              {
                path: "",
                name: "helplist",
                component: () => import("@/views/Help-List-View.vue"),
              },
              {
                path: "lesson/:id",
                name: "lesson",
                component: () => import("@/views/Help-Detail-View.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  if (!userStore.isLoggedIn() && (to.name !== "main" && (to.name !== "Redirect"))) {
    window.location.href = "/";
  } else {
    next();
  }
});

export default router;
