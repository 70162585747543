// The delay constant naming goes by convention {pagename}_{feature}
// Delay constants

const cjConstant = {
  CRONJOB: {
    DASHBOARD_CRITICAL_ERRORS_WIDGET: "DASHBOARD_CRITICAL_ERRORS_WIDGET",
    DASHBOARD_DISARMED_ALARM_SITES_WIDGET:
      "DASHBOARD_DISARMED_ALARM_SITES_WIDGET",
    EVENTS_LIST_LOAD_EVENTS: "EVENTS_LIST_LOAD_EVENTS",
    APP_SESSION_CHECK: "APP_SESSION_CHECK",
    APP_VERSION_CHECK: "APP_VERSION_CHECK",
    EVENT_HISTORY_RECORD_LIST: "EVENT_HISTORY_RECORD_LIST",
    ALARM_LIST_SITE: "ALARM_LIST_SITE",
  },
  DELAY: {
    DASHBOARD_CRITICAL_ERRORS_WIDGET: 10000,
    DASHBOARD_DISARMED_ALARM_SITES_WIDGET: 10000,
    EVENTS_LIST_LOAD_EVENTS: 10000,
    APP_SESSION_CHECK: 300000,
    APP_VERSION_CHECK: 10000,
    EVENT_HISTORY_RECORD_LIST: 10000,
    ALARM_LIST_SITE: 10000,
  },
};

export default cjConstant;
