import { defineStore } from "pinia";
import axios from "axios";


const useVersionStore = defineStore("version", () => {
    const currentVersion = `__APP_VERSION`;
    let serverVersion = "";

    function getCurrentVersion() {
        return currentVersion
    }

    async function getServerVersion() {
      if(serverVersion !== "") {
        return serverVersion;
      }

      const versionResponse = await axios.get('/version.json');

      serverVersion = versionResponse?.data?.VERSION;
      return serverVersion;
    }

    return { getCurrentVersion, getServerVersion };
  });

  export default useVersionStore;

