/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'

//Font Awesome
import { aliases, fa } from 'vuetify/iconsets/fa-svg';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default createVuetify({
  components: {
    ...components,
    ...labsComponents,
    FontAwesomeIcon
  },
  theme: {
    defaultTheme: window.localStorage.getItem("currentTheme") || "dark",
    themes: {
      dark: {
        colors: {
          primary: "#F44336",
          secondary: "#cccccc",
          "surface-bright": "#363640",
          tertiary: "#FFFFFF",
        },
      },
      light: {
        colors: {
          primary: "#F44336",
          background: "#e9ecef",
          secondary: "#484848",
          surface: "#ffffff",
          "surface-bright": "#c0c0c0",
          error: "#e5383b",
          tertiary: "#000000",
        },
      },
    },
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa
    },
  },
});
