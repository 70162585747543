import EN from "./en.json";
import ES from "./es.json";

// https://translate.i18next.com/ is an option for creating translations

const messages = {
  en: EN,
  es: ES,
};

export default messages;
