import axios from "axios";
import getApiUrl from "./getApiUrl";

const axiosInstance = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
  headers: {
    common: {
      "X-Requested-With": "CS",
    },
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.warn(`error caught (${error.code}) message: ${error.message} data: ${error.response ? error.response.data : null}`)
    if (error.response) {
      // for unauthorized or forbidden throw error for Login else go back to home page
      if (error.response.status === 401 || error.response.status === 403) {
        if (error.request.responseURL.endsWith("/User/Login")) {
          throw new Error(error.response.data ? error.response.data : "Internal error");
        }
        window.location.href = "/";
      }
    }
    throw new Error(error.response ? error.response.data : error.message);
  }
);

// Your request and response interceptors will go here

export default axiosInstance;
