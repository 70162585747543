import { defineStore } from "pinia";
import { ref } from "vue";
import { useI18n } from 'vue-i18n'

const useErrorStore = defineStore("error", () => {
  const errors = ref([]);
  const { t } = useI18n()

  // errorObj : {code, message}
  function setError(errorObj) {
    // do translation if we got parameters
    if (errorObj.params) {
      errorObj.message = t(errorObj.code, errorObj.params);
    }
    // allow for a separate object code than the code used for message translation
    errorObj.objectCode = errorObj.objectCode ? errorObj.objectCode : errorObj.code;
    let newError = true;
    const newErrors = JSON.parse(JSON.stringify(errors.value));
    // Check if new error is already displayed if yes then just update the message
    // This is useful when there is recurring call for a function that keeps throwing error
    newErrors.every((error) => {
      if (error.objectCode === errorObj.objectCode) {
        error.message === errorObj.message;
        newError = false;
        return false;
      }
      return true;
    });
    if (newError) {
      errors.value = [...newErrors, errorObj];
    } else {
      errors.value = [...newErrors];
    }
  }

  function clearAllErrors() {
    errors.value = [];
  }

  return { setError, errors, clearAllErrors };
});

export default useErrorStore;
