/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
import pinia from '../store'
import router from '../router'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import { fasr } from '@fortawesome/sharp-regular-svg-icons';
import { fasl } from '@fortawesome/sharp-light-svg-icons';

export function registerPlugins (app) {
  loadFonts();

  // Setup FontAwesome for use in Vue & Vuetify, ensure only required fonts are added.

  app.component('font-awesome-icon', FontAwesomeIcon);
  library.add(fas); // FA Solid Icons
  library.add(far); // FA Regular Icons
  //library.add(fal); // FA Light Icons
  //library.add(fad); // FA Duotone Icons
  //library.add(fass); // FA Sharp Light Icons
  //library.add(fasr); // FA Sharp Light Icons
  //library.add(fasl); // FA Sharp Light Icons
  //library.add(fat); // FA Thin Icons

  app
    .use(vuetify)
    .use(router)
    .use(pinia);
}
