/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import { createApp } from "vue";
import { registerPlugins } from "@/plugins";
import App from "./App.vue";
import axios from "@/utils/axios";
import messages from "./locale/index";
import { createI18n } from "vue-i18n";
import router from "./router";

function getLang() {
  let localeString = null;
  if (navigator.languages != undefined) {
    // check for 2 character version
    for (let language in navigator.languages) {
      if (!localeString) {
        localeString = navigator.languages[language];
      } else if (language.length == 2) {
        localeString = navigator.languages[language];
        break;
      }
    }
  }
  if (!localeString) {
    localeString = navigator.language;
  }
  if (localeString && localeString.length > 2) {
    localeString = localeString.slice(0, 2);
  }
  console.log(`Using language ${localeString}`);
  return localeString;
}

router.beforeEach((to, from, next) => {
  // if (to.name === 'eventslistitem') {
  //   document.body.classList.add('no-scroll');
  // } else {
  //   document.body.classList.remove('no-scroll');
  // }
  next();
});

const i18n = createI18n({
  legacy: false,
  locale: getLang(),
  fallbackLocale: "en",
  messages: messages,
});

const app = createApp(App);

app.use(i18n);

/*
    Items below are added to handle integration between Maui and the Vue app as
    Maui needs functions in some accessible name space.
    See also the maui.js file for more on the integration.
*/
import { setApplicationForMAUI } from "./maui";
setApplicationForMAUI(app);
/* END MAUI*/

app.provide("$http", axios);

registerPlugins(app);

app.mount("#app");
