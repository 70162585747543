import { defineStore } from 'pinia'
import { ref, inject } from 'vue'
import Storage from '@/utils/storage'
import { STORAGE } from '@/constants/storage'
import useErrorStore from './error'
import { useI18n } from 'vue-i18n'
import { DEFAULT_APP_LOGO } from '@/constants/app'

const useUserStore = defineStore('user', () => {
  const loading = ref(false)
  const errorStore = useErrorStore()
  const { t } = useI18n()
  const http = inject('$http')
  const defaultProfile = {
    Title: null,
    Styles: null,
    Logo: null,
    LogoType: null,
    LinkVersion: null,
    Name: ''
  }

  function setProfile(profile) {
    Storage.setItem(STORAGE.LOGGED_IN_USER_PROFILE, profile || defaultProfile)
  }

  function getProfile() {
    const userProfile = Storage.getItem(STORAGE.LOGGED_IN_USER_PROFILE)
    return userProfile
  }

  function setLogo(profile) {
    if (profile?.Logo && profile?.LogoType) {
      //convert binary to ascii
      var b64 = btoa(atob(profile.Logo))
      // a DataURL is a Base64 string with a header, use this to work across page refreshes
      var dataURL = `data:${profile.LogoType};base64,${b64}`
      Storage.setItem(STORAGE.APP_LOGO, dataURL)
    } else {
      Storage.setItem(STORAGE.APP_LOGO, null)
    }
  }

  function getLogo() {
    let logo
    if (isLoggedIn()) {
      logo = Storage.getItem(STORAGE.APP_LOGO) || DEFAULT_APP_LOGO
    } else {
      logo = DEFAULT_APP_LOGO
    }
    return logo
  }

  function isLoggedIn() {
    const user = Storage.getItem(STORAGE.LOGGED_IN_USER_PROFILE)
    if (user?.Name) {
      return true
    }
    return false
  }

  function getName() {
    const user = Storage.getItem(STORAGE.LOGGED_IN_USER_PROFILE)
    return user?.Name
  }

  function clearInfo() {
    Storage.removeItem(STORAGE.LOGGED_IN_USER_PROFILE)
  }

  async function login({ username, password }) {
    loading.value = true
    try {
      const {
        status = null,
        data = null,
        headers = null
      } = await http.post('User/Login', {
        username,
        password
      })
      if (status == 200) {
        loading.value = false
        return { headers, status, data }
      } else {
        loading.value = false
        throw new Error('System Error')
      }
    } catch (error) {
      loading.value = false
      throw new Error(t('LOGIN_FAILED', [error.message]))
    }
  }

  async function logout() {
    try {
      await http.post('User/Logout')
    } catch (error) {
      errorStore.setError({
        code: 'LOGOUT_FAILED',
        message: `Could not logout. ${error?.message}`
      })
    }
  }

  async function checkUserSession() {
    try {
      await http.get('User/SessionCheck')
    } catch {}
  }

  function setTheme(theme = 'dark') {
    Storage.setItem(STORAGE.CURRENT_THEME, theme)
  }

  function getTheme() {
    return Storage.getItem(STORAGE.CURRENT_THEME) || 'dark'
  }

  return {
    login,
    logout,
    setProfile,
    getName,
    clearInfo,
    checkUserSession,
    setTheme,
    getTheme,
    loading,
    isLoggedIn,
    getProfile,
    setLogo,
    getLogo
  }
})

export default useUserStore
