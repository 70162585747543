<template>
    <v-dialog v-model="modalActive" v-show="modalActive" persistent width="auto">
        <v-card>
            <v-card-text>
                <!-- Modal Content -->
                <slot />
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" block @click="$emit('close')">{{t('COMMON_OK')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n'

const props = defineProps(['modalActive'])
const { t } = useI18n()

const modalActive = computed(() => {
    return props.modalActive;
})

</script>
