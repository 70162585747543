import useUserStore from './store/user';
import getApiUrl from "./utils/getApiUrl";

export function setApplicationForMAUI(app) {
    window.myVueApp = app;    
    // window.myVueApp contains the main app
    window.myVueApp.maui = {};

    window.myVueApp.maui._mauiEnabled = false;
    window.myVueApp.maui._data = [];
    window.myVueApp.maui._dataId = 0;
    window.myVueApp.maui._theme = null;

    // define global functions needed to be called from MAUI

    // This function is called by Maui to get the data that the caller wants to pass
    window.myVueApp.maui.getData = function (dataId) {
        const data = window.myVueApp.maui._data.find(i => i.dataId === dataId);
        window.myVueApp.maui._data.splice(window.myVueApp.maui._data.indexOf(data), 1);
        return data;
    }

    window.myVueApp.maui.pushRegistered = async function(appId, platform) {
        console.log(`Registered app with id ${appId} for platform ${platform}`);
        var url = getApiUrl() + 'v2/User/DeviceRegister';
        const response = await fetch(url,  
            {
                method: 'POST',
                body: JSON.stringify(
                    {
                        platform: platform,
                        deviceid: appId
                    }
                ),
                credentials: 'include',
                headers: { "X-Requested-With": "CS" }
            }
        );
        if (!response.ok) {
            console.error(`Status code ${response.status} received for platform: ${platform}`);
        }
    }

    window.myVueApp.maui.updateTheme = async function(newTheme) {
        console.log(`updateTheme to ${newTheme} from MAUI`);
        const userStore = useUserStore();
        userStore.setTheme(newTheme)
        if (window.myVueApp.maui._theme) {
            window.myVueApp.maui._theme.global.name.value = newTheme;
        }
    }
        
}

export function dialPhoneNumber(phoneNumber) {
    console.log("dialPhoneNumber");
    invokeCs("DialPhone", phoneNumber);
}

export function registerPushNotifications() {
    console.log("registerPushNotifications");
    invokeCs("RegisterPush");
}

export function setThemeObject(theme) {
    window.myVueApp.maui._theme = theme;
}

export function setThemeMAUI(themeString) {
    console.log("setThemeMAUI");
    invokeCs("SetTheme", themeString);
}

//Registers the data and calls the C# side
function invokeCs(command, data) {
    if (!window.myVueApp.maui._mauiEnabled) {
        console.warn(`MAUI native integration not enabled! Command: ${command}`);
        return;
    }
    //C# is calling getData async, so we have to provide a kind of data Store to be kind of "thread safe"
    window.myVueApp.maui._dataId++;
    if (window.myVueApp.maui._dataId > 10000) {
        window.myVueApp.maui._dataId = 1;
    }
    const dataId = window.myVueApp.maui._dataId;

    const csData = new CsData(dataId, command, data);
    window.myVueApp.maui._data.push(csData);

    //Call the C# side - on the C# side the navigation is canceled but the command call will be executed after getting the data with dataId
    // You can also send smaller amounts of data directly here in the url, but there is a limit al little above 50K (URL should stay below 50K)
    window.location = "/maui/" + dataId;
}

class CsData {
    constructor(dataId, command, data) {
        this.dataId = dataId;
        this.command = command;
        this.data = data;
    }
}