<template>
  <router-view />
  <confirmation @close="toggleModal" :modalActive="modalActive">
    <div>
      {{ confirmationMessage }}
    </div>
  </confirmation>
</template>

<script setup>
import { useTheme } from 'vuetify';
import useUserStore from './store/user';
import { setThemeObject } from '@/maui';
import { onMounted, ref } from 'vue';
import useVersionStore from '@/store/version';
import cjConstant from '@/constants/cronjob';
import CronJob from '@/utils/cronjob';
import Confirmation from '@/components/Viewer/Confirmation.vue';

const theme = useTheme()
const userStore = useUserStore()
theme.global.name.value = userStore.getTheme();
setThemeObject(theme);

const modalActive = ref(false)
const confirmationMessage = ref('')

const versionStore = useVersionStore()

async function checkVersion() {
  const currentVersion = versionStore.getCurrentVersion()
  const serverVersion = await versionStore.getServerVersion()
  if (serverVersion != null && serverVersion != currentVersion) {
    console.log(`Version update from ${currentVersion} to ${serverVersion} available`)
    confirmationMessage.value = `Version update from ${currentVersion} to ${serverVersion} available, reloading`;
    toggleModal()
  }
}

function toggleModal() {
  modalActive.value = !modalActive.value;
  if (!modalActive.value) {
    // user is done with the dialog so refresh
    window.location.href = "/loggedin/dashboard"
  }
};

onMounted(() => {
  const currentVersionToLog = versionStore.getCurrentVersion()
  console.log(`app loaded, current version: ${currentVersionToLog}`)
  CronJob.init([
    {
      name: cjConstant.CRONJOB.APP_VERSION_CHECK,
      callback: () => {
        checkVersion()
      },
      delay: cjConstant.DELAY.APP_VERSION_CHECK
    }])
})

</script>

<style>
.no-scroll {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  /* Prevent the entire page from scrolling */
}
</style>